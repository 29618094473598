export { DOMReady, ValidateEmail, ExcludeLetters, getMobileOperatingSystem }
// We are loading async so we can't use DOMContentLoaded plainly
let DOMReady = function (callback) {
  document.readyState === 'interactive' || document.readyState === 'complete'
    ? callback()
    : document.addEventListener('DOMContentLoaded', callback())
}

let GoBackWithRefresh = function (event) {
  if ('referrer' in document) {
    window.location = document.referrer
    /* OR */
    //location.replace(document.referrer);
  } else {
    window.history.back()
  }
}

function ValidateEmail(el) {
  el.addEventListener('keydown', (event) => {
    var timeout
    var check = el.parentNode.querySelector('.fa-check').parentNode
    if (event.isComposing || event.keyCode === 229) {
      return // For special keyboards
    }
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    timeout = setTimeout(function () {
      var booking_email = el.value
      if (/(.+)@(.+){2,}\.(.+){2,}/.test(booking_email)) {
        // valid email
        check.classList.remove('is-hidden')
        return true
      } else {
        // invalid email
        if (!check.classList.contains('is-hidden')) {
          check.classList.add('is-hidden')
        }
        return false
      }
    }, 2000)
  })
}

function ExcludeLetters() {
  let numberinputs = document.querySelectorAll('input[type=number]')
  Array.prototype.forEach.call(numberinputs, (numberinput) => {
    numberinput.addEventListener('keydown', (e) => {
      var key = e.which || e.charCode || e.keyCode || 0
      if (
        key != 9 && // allow tab
        key != 127 &&
        key != 8 &&
        key != 190 &&
        (key < 45 || key > 57) &&
        !(e.keyCode >= 96 && e.keyCode <= 105)
      ) {
        e.preventDefault()
        return key == null
      }
    })
  })
}

/**
 * OS detection
 */
var getMobileOperatingSystem = function () {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera
  // Windows Phone must come first because its UA also contains "Android"
  if (/android/i.test(userAgent)) {
    return 'Android'
  }
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  }
  return 'unknown'
}

/**
 * Dark mode detection
 * https://stackoverflow.com/questions/50819734/detect-dark-mode-in-javascript
 */
function isDarkMode() {
  return (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  )
}
// now toggle the dark mode watching for changes
window
  .matchMedia('(prefers-color-scheme: dark)')
  .addEventListener('change', (e) => {
    const newColorScheme = e.matches ? 'dark' : 'light'
    //add dark class to body to enable

  }
)

