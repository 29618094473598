/** Bulma tabs
 * https://stackoverflow.com/questions/44891771/bulma-css-tab-switcher#45010416
 * 4 item preceived stress scale
 * https://www.researchgate.net/figure/Standardized-estimates-of-the-Occupational-Fatigue-Exhaustion-Recovery-scale-adjusted_fig1_7790458
 *
 */


/**
 * For Bulma tabs https://bulma.io/documentation/components/tabs/
 */
function openTab(evt, tabTitle) {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    // set tab content to blank - change this maybe
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");

    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" is-active", "");
    }
    // Show the tab content
    document.getElementById(tabTitle).style.display = "block";
    evt.currentTarget.className += " is-active";
}
