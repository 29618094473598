import { DOMReady, ExcludeLetters } from './global.js'
import navburger from './navburger.js'

DOMReady(function () {
  /**
   * Set the parallax up
   */
  if (typeof simpleParallax !== 'undefined') {
    var images = document.querySelectorAll('img.parallax')
    new simpleParallax(images, { scale: 1.4 })
  }

  /**
   * Set the footer
   */
  document.getElementById('footer-year').textContent = new Date().getFullYear()

  navburger()

  /**
   * for input[type=number]
   */
  ExcludeLetters()
})
/**
 * Screen size detection
 */

const screenWidth =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth
const screenHeight =
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight
fetch('/api/v1/set-device-dims', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
  },
  body: JSON.stringify({
    width: screenWidth,
    height: screenHeight,
  }),
}).then((response) => response.json())

/**
 * Get all disabled buttons and make them not clickable
 */
const disabledButtons = document.querySelectorAll('[disabled]')
disabledButtons.forEach((button) => {
  button.addEventListener('click', (event) => {
    event.preventDefault()
  })
});
